///////////////////////////////////////////////////////////////////////////////////MODULES
import { CustomToast, Col, Row, CustomToastHeader, CustomToastBody } from '@ibiliaze/reactstrap';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const About = () => (
  <section id='about-section' className='page p-t-xl p-b-xl'>
    <h1 className='t-align-c'>
      Experience Barber <span className='text-gradient'>Excellence</span>
    </h1>
    <Row>
      {constants.aboutUs.map((content, i) => (
        <Col key={i} md={4}>
          <CustomToast>
            <CustomToastHeader>{content.header} </CustomToastHeader>
            <CustomToastBody>{content.body}</CustomToastBody>
          </CustomToast>
        </Col>
      ))}
    </Row>
  </section>
);

export default About;
