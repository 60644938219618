///////////////////////////////////////////////////////////////////////////////////MODULES
import { Col, Row, MainImage, CustomButton } from '@ibiliaze/reactstrap';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants from '../../utils/constants';
import scroll from '../../utils/scroll';
//////////////////////////////////////////////////////////////////////////////////////////

const Copy = () => (
  <>
    <section className='page p-t-xl p-b-xl'>
      <h1 className='t-align-c'>Book On The Go</h1>
      <Row>
        <Col md={6}>
          <h3>
            <span className='text-gradient'>Easier</span> for You
          </h3>
          <p>
            With <b>{constants.cust}</b>, customers have the option to easily book appointments either through their
            mobile device or desktop computer. On the go? Simply visit our mobile-friendly website and schedule your
            appointment in just a few taps. Prefer to use your desktop? No problem, visit our website and follow the
            simple steps to secure your appointment time. Either way, booking with <b>{constants.cust}</b> has never
            been easier and more convenient.
          </p>
          <CustomButton onClick={_ => scroll(document.getElementById('services-section')?.offsetTop)} color='primary'>
            Choose your Service
          </CustomButton>
        </Col>
        <Col md={2}>
          <img alt='phone' src='/img/phone.png' style={{ width: '100%' }} />
        </Col>
        <Col md={4}>
          <img alt='phone' src='/img/surface.png' style={{ width: '100%' }} />
        </Col>
      </Row>
    </section>
    <div className='p-t-xl p-b-xl'>
      <MainImage
        imgPath='/img/main2.jpg'
        imgHeight='500px'
        imgMobileHeight='500px'
        imgBrightness='0.4'
        slogan={`At ${constants.cust}, we believe that a great haircut starts with a solid foundation. Our barbers are trained in the latest techniques and are equipped with the best tools to ensure you receive the perfect cut every time. Our fundamental principles include attention to detail, comfort, and a personalized experience for every customer.`}
      />
    </div>
  </>
);

export default Copy;
