///////////////////////////////////////////////////////////////////////////////////MODULES
import { Footer as CustomFooter } from '@ibiliaze/reactstrap';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants from '../../utils/constants';
import { resolveHost } from '../../utils/resolveEnv';
//////////////////////////////////////////////////////////////////////////////PACKAGE JSON
import packageJson from '../../../package.json';
//////////////////////////////////////////////////////////////////////////////////////////

const Footer = () => (
  <CustomFooter
    title={constants.cust}
    textButtons={[
      {
        title: `📞 ${constants.phone1}`,
        onClickHandler: _ => (window.location.href = `tel:${constants.phone1}`),
      },
      // {
      //   title: `📧 ${constants.email1}`,
      //   onClickHandler: _ => (window.location.href = `mailto:${constants.email1}`),
      // },
      {
        title: `📍 ${constants.hqAddr}`,
        onClickHandler: _ => window.open(`https://www.google.com/maps/dir/?api=1&destination=${constants.hqAddr}`),
      },
    ]}
    navLinks={[
      {
        title: 'Privacy Policy',
        href: resolveHost() + '/privacy-policy',
      },
      {
        title: 'Terms & Conditions',
        href: resolveHost() + '/terms-conditions',
      },
      {
        title: 'Disclaimer',
        href: resolveHost() + '/disclaimer',
      },
    ]}
    miniTexts={[`Version: ${packageJson.version}`]}
  />
);

export default Footer;
