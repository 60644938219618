////////////////////////////////////////////////////////////////////////////////COMPONENTS
import { MainImage } from '@ibiliaze/reactstrap';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import About from '../Dashboard/About';
import Copy from '../Dashboard/Copy';
import Reviews from '../Dashboard/Reviews';
import Services from '../Dashboard/Services';
import Banner from '../Dashboard/Banner';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants from '../../utils/constants';
import scroll from '../../utils/scroll';
//////////////////////////////////////////////////////////////////////////////////////////

const HomePage = () => (
  <>
    <MainImage
      imgPath={constants.mainPic}
      logoPath={constants.mainPicLogo}
      logoHeight='10%'
      logoLeft='20px'
      logoBottom='20px'
      facebookHandle={constants.facebook}
      instagramHandle={constants.instagram}
      twitterHandle={constants.twitter}
      slogan={constants.slogan}
      title={constants.cust.toUpperCase()}
      onButtonClick={_ => scroll(document.getElementById('services-section')?.offsetTop)}
      buttonText={constants.mainPicButton}
      infoTop='57%'
      imgBrightness='.3'
      imgHeight='400px'
      imgMobileHeight='300px'
      titleLetterSpacing='0.3rem'
      titleCentre={true}
      sloganCentre={true}
      mobileWidth={700}
    />
    <Banner />
    <About />
    <Copy />
    <Services />
    <Reviews />
  </>
);

export default HomePage;
