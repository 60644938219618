///////////////////////////////////////////////////////////////////////////////////MODULES
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CustomButton,
  FormText,
  Label,
  FormGroup,
  Form,
  FormFeedback,
  CustomInput,
  Table,
  Spinner,
  Row,
  Col,
} from '@ibiliaze/reactstrap';
import { hmToMs, msToDHM, msToHm } from '@ibiliaze/time';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { putSettings } from '../../actions/settings';
//////////////////////////////////////////////////////////////////////////////////////////

const Settings = ({ toggle, modal, settings, putSettings }) => {
  // State
  const [inputs, setInputs] = useState({
    workingHours: {},
    interval: 0,
    roundBooking: '',
    type: 'global',
  });
  const [earliestBooking, setEarliestBooking] = useState({ days: 0, hours: 0, mins: 0 });
  const [loading, setLoading] = useState(false);

  // onChange functions
  const onStartTimeChange = (dow, time) =>
    setInputs({
      ...inputs,
      workingHours: { ...inputs.workingHours, [dow]: { ...inputs.workingHours[dow], start: hmToMs(time) } },
    });
  const onEndTimeChange = (dow, time) =>
    setInputs({
      ...inputs,
      workingHours: { ...inputs.workingHours, [dow]: { ...inputs.workingHours[dow], end: hmToMs(time) } },
    });
  const onIntervalClick = e => setInputs({ ...inputs, interval: hmToMs(e.target.value) });
  const onRoundClick = e => setInputs({ ...inputs, roundBooking: e.target.value });
  const onEarliestBookingChange = e => setEarliestBooking({ ...earliestBooking, [e.target.name]: e.target.value });

  // Lifecycle hooks
  useEffect(() => {
    const { days, hours, mins } = msToDHM(settings?.earliestBooking);
    setInputs({ ...settings });
    setEarliestBooking({ days, hours, mins });
  }, [settings]);

  // onSubmit functions
  const onSaveClick = e => {
    e.preventDefault();
    setLoading(true);
    putSettings({
      ...inputs,
      earliestBooking:
        earliestBooking.days * 24 * 3600000 + earliestBooking.hours * 3600000 + earliestBooking.mins * 60000,
    });
    setLoading(false);
  };

  // JSX
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <Form onSubmit={onSaveClick}>
        <ModalHeader toggle={toggle}>Settings</ModalHeader>
        <ModalBody>
          <FormGroup>
            <h4 className='t-align-c'>Working hours</h4>
            <Table borderless>
              <thead>
                <tr>
                  <th></th>
                  <th>Starting Hours</th>
                  <th>Ending Hours</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(inputs.workingHours).map((dow, i) => {
                  const wh = inputs.workingHours[dow];
                  return (
                    <tr key={i}>
                      <th scope='row'>{dow}</th>
                      <td>
                        <CustomInput
                          value={msToHm(wh.start)}
                          onChange={e => onStartTimeChange(dow, e.target.value)}
                          type='time'
                          className='input-block'
                        />
                      </td>
                      <td>
                        <CustomInput
                          value={msToHm(wh.end)}
                          onChange={e => onEndTimeChange(dow, e.target.value)}
                          type='time'
                          className='input-block'
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </FormGroup>

          <hr />
          <FormGroup>
            <Label>Round</Label>
            <CustomInput value={inputs.roundBooking} onChange={onRoundClick} type='select'>
              <option>up</option>
              <option>down</option>
              <option>nearest</option>
            </CustomInput>
            <FormText>
              Up: any minute over the interval will round to the next time block. Down: any minute over the interval
              will round to the down the initial time block. Nearest: will round to the nearest time block.
            </FormText>
          </FormGroup>

          <hr />
          <FormGroup>
            <Label>Time interval</Label>
            <CustomInput invalid value={msToHm(inputs.interval)} onChange={onIntervalClick} type='time' />
            <FormFeedback>
              We recommend that you do NOT change this value regularly as it can cause hidden bookings.
            </FormFeedback>
            <FormText>
              This determines the minimum time block for the booking table. The total number of time blocks should fit
              perfectly with the given start and end work hours.
            </FormText>
          </FormGroup>

          <hr />
          <FormGroup>
            <Label>Earliest booking time</Label>
            <Row>
              <Col md={4}>
                <Label>days</Label>
                <CustomInput
                  name='days'
                  value={earliestBooking.days}
                  onChange={onEarliestBookingChange}
                  type='number'
                />
              </Col>
              <Col md={4}>
                <Label>hours</Label>
                <CustomInput
                  name='hours'
                  value={earliestBooking.hours}
                  onChange={onEarliestBookingChange}
                  type='number'
                />
              </Col>
              <Col md={4}>
                <Label>minutes</Label>
                <CustomInput
                  name='mins'
                  value={earliestBooking.mins}
                  onChange={onEarliestBookingChange}
                  type='number'
                />
              </Col>
            </Row>
            <FormText>This determines the earliest booking a client can make from now.</FormText>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <CustomButton disabled={loading ? true : false} type='submit' color='primary'>
            {loading ? <Spinner size='sm' /> : 'Save'}
          </CustomButton>
          <CustomButton onClick={_ => toggle()}>Cancel</CustomButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const mapStateToProps = state => ({ settings: state.settings });

export default connect(mapStateToProps, { putSettings })(Settings);
