///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Offcanvas, OffcanvasHeader, OffcanvasBody, Table, CustomNav } from '@ibiliaze/reactstrap';
import { msToHm } from '@ibiliaze/time';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { getUser, logout } from '../../actions/user';
import { getSettings } from '../../actions/settings';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants from '../../utils/constants';
import scroll from '../../utils/scroll';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import UserForm from './UserForm';
import Wishlist from '../Booking/Wishlist';
import Settings from './Settings';
//////////////////////////////////////////////////////////////////////////////////////////

const Header = ({ user, wishlist, settings, getUser, getSettings, logout }) => {
  // State
  const [loginModal, setLoginModal] = useState(false);
  const [wishlistModal, setWishlistModal] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);
  const [offcanvas, setOffcanvas] = useState(false);

  // Toggle functions
  const toggleLoginModal = () => setLoginModal(!loginModal);
  const toggleWishlistModal = () => setWishlistModal(!wishlistModal);
  const toggleSettingsModal = () => setSettingsModal(!settingsModal);
  const toggleOffcanvas = () => setOffcanvas(!offcanvas);

  // Navigate
  const navigate = useNavigate();

  // onClick functions
  const onLoginClick = () => toggleLoginModal();
  const onWishlistClick = () => toggleWishlistModal();
  const onSettingsClick = () => toggleSettingsModal();

  // Lifecycle hooks
  useEffect(() => {
    // If authentication is succesful redirect to the home page and set the current object states
    if (!!user && user.isAuthenticated) {
      try {
        getUser();
        getSettings();
      } catch (e) {
        console.log(e);
      }
      loginModal && setLoginModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.isAuthenticated]);

  // JSX
  return (
    <>
      <CustomNav
        dark={true}
        logoSrc={constants.mainPicLogo}
        logoStyle={{ height: '40px' }}
        navbarBrand={constants.cust}
        navbarBrandHref='#'
        navbarBrandOnClick={_ => navigate('/')}
        navlinks={[
          {
            title: 'Bookings',
            href: '#',
            onClick: _ => {
              window.scrollTo(0, 0);
              navigate('/bookings');
            },
            hide: false,
          },
          {
            title: 'About',
            href: '#',
            onClick: _ => {
              navigate('/');
              scroll(document.getElementById('about-section')?.offsetTop);
            },
            hide: false,
          },
          {
            title: 'Services',
            href: '#',
            onClick: _ => {
              navigate('/');
              scroll(document.getElementById('services-section')?.offsetTop);
            },
            hide: false,
          },
          {
            title: 'Contact',
            href: '#',
            onClick: _ => {
              window.scrollTo(0, document.body.scrollHeight);
            },
            hide: false,
          },
        ]}
        navlinksRight={[
          // auth links
          {
            title: 'Settings ⚙',
            href: '#',
            onClick: onSettingsClick,
            hide: !user.isAuthenticated,
          },
          {
            title: user && user.user ? `${user.user.username}@${constants.cust}` : '',
            text: true,
            hide: !user.isAuthenticated,
          },
          {
            title: 'Logout',
            href: '#',
            onClick: _ => logout(),
            hide: !user.isAuthenticated,
          },

          // guest links
          {
            title: `Wishlist ♥ ${wishlist.items.length}`,
            href: '#',
            onClick: _ => onWishlistClick(),
            hide: user.isAuthenticated,
            badge: true,
            badgeColour: 'danger',
          },
          {
            title: 'Visit us',
            href: '#',
            onClick: toggleOffcanvas,
            hide: user.isAuthenticated,
          },
          {
            title: 'Login',
            href: '#',
            onClick: onLoginClick,
            hide: user.isAuthenticated,
          },
        ]}
      />
      <Offcanvas direction='end' fade scrollable isOpen={offcanvas} toggle={toggleOffcanvas}>
        <OffcanvasHeader toggle={toggleOffcanvas}>Working Hours</OffcanvasHeader>
        <OffcanvasBody>
          <Table borderless className='wishlist-table'>
            <tbody>
              {Object.keys(settings.workingHours).map((dow, i) => {
                const wh = settings.workingHours[dow];
                return (
                  <tr key={i}>
                    <th scope='row'>{dow}</th>
                    <td>{wh.start === wh.end ? 'Closed' : msToHm(wh.start)}</td>
                    <td>{wh.start === wh.end ? 'Closed' : msToHm(wh.end)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </OffcanvasBody>
      </Offcanvas>
      <UserForm modal={loginModal} toggle={toggleLoginModal} />
      <Wishlist modal={wishlistModal} toggle={toggleWishlistModal} />
      <Settings modal={settingsModal} toggle={toggleSettingsModal} />
    </>
  );
};

const mapStateToProps = state => ({ wishlist: state.wishlist, user: state.user, settings: state.settings });

export default connect(mapStateToProps, { getUser, getSettings, logout })(Header);
