///////////////////////////////////////////////////////////////////////////////////MODULES
import { connect } from 'react-redux';
import { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CustomButton,
  Label,
  FormGroup,
  Form,
  InputGroup,
  FormText,
  CustomInput,
  TextButton,
  Spinner,
} from '@ibiliaze/reactstrap';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { login, register, putUserPassword } from '../../actions/user';
import { confirmEmail } from '../../actions/email';
//////////////////////////////////////////////////////////////////////////////////////////

const UserForm = ({ toggle, modal, login, register, putUserPassword, confirmEmail }) => {
  const [inputs, setInputs] = useState({ authType: 'Login', email: '', username: '', password: '', adminKey: '' });
  const [loading, setLoading] = useState(false);

  // onChange functions
  const onInputsChange = e => setInputs({ ...inputs, [e.target.name]: e.target.value });

  // onClick functions
  const onSendConfirmationClick = _ => confirmEmail({ email: inputs.email?.toLowerCase() });

  // onSubmit function
  const onSubmit = e => {
    e.preventDefault();
    setLoading(true);
    const req =
      inputs.authType === 'Register' ? register : inputs.authType === 'Reset Password' ? putUserPassword : login;
    req({ ...inputs, email: inputs.email?.toLowerCase() });
    setLoading(false);
  };

  // JSX
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <Form onSubmit={onSubmit}>
        <ModalHeader toggle={toggle}>{inputs.authType}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Email</Label>
            <InputGroup>
              <CustomInput value={inputs.email} onChange={onInputsChange} name='email' placeholder='user@example.com' />
              {inputs.authType !== 'Login' && (
                <>
                  <CustomButton onClick={onSendConfirmationClick} color='success'>
                    Verify
                  </CustomButton>
                </>
              )}
            </InputGroup>
          </FormGroup>
          {inputs.authType !== 'Login' && (
            <FormGroup>
              <Label>One Time Passcode</Label>
              <CustomInput
                required
                name='code'
                placeholder='One Time Passcode'
                value={inputs.node}
                onChange={onInputsChange}
              />
              <FormText>Your One Time Passcode (OTP) can only be used once</FormText>
            </FormGroup>
          )}
          {inputs.authType === 'Register' && (
            <>
              <FormGroup>
                <Label>Username</Label>
                <CustomInput value={inputs.username} onChange={onInputsChange} name='username' placeholder='Jonny' />
              </FormGroup>
              <FormGroup>
                <Label>Admin Key</Label>
                <CustomInput
                  value={inputs.adminKey}
                  onChange={onInputsChange}
                  name='adminKey'
                  placeholder='4e92736ff6aerf4cdf1c73d5e348f'
                />
              </FormGroup>
            </>
          )}
          <FormGroup>
            <Label>Password</Label>
            <CustomInput
              value={inputs.password}
              onChange={onInputsChange}
              name='password'
              type='password'
              placeholder='password'
            />
          </FormGroup>
          <span className='form-label'>
            {inputs.authType === 'Login' ? "Don't have an account? " : 'Already have an account? '}
          </span>
          <TextButton
            onClick={_ => setInputs({ ...inputs, authType: inputs.authType !== 'Login' ? 'Login' : 'Register' })}
          >
            {inputs.authType !== 'Login' ? 'Login' : 'Register'}
          </TextButton>

          <div>
            <span className='form-label'>Forgot your password? </span>
            <TextButton onClick={_ => setInputs({ ...inputs, authType: 'Reset Password' })}>
              Reset your password
            </TextButton>
          </div>
        </ModalBody>
        <ModalFooter>
          <CustomButton disabled={loading ? true : false} type='submit' color='primary'>
            {loading ? <Spinner size='sm' /> : inputs.authType}
          </CustomButton>
          <CustomButton onClick={_ => toggle()}>Cancel</CustomButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default connect(null, { login, register, putUserPassword, confirmEmail })(UserForm);
