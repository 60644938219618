////////////////////////////////////////////////////////////////////////////////COMPONENTS
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  CustomCarousel,
  TextButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CustomButton,
  CustomInput,
  Form,
  FormGroup,
  InputGroup,
  FormText,
  Spinner,
} from '@ibiliaze/reactstrap';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { confirmEmail } from '../../actions/email';
import { getReviews, postReview } from '../../actions/reviews';
//////////////////////////////////////////////////////////////////////////////////////////

const Reviews = ({ reviews, getReviews, postReview, confirmEmail }) => {
  // State
  const [inputs, setInputs] = useState({ title: '', email: '', phone: '', code: '', stars: 5, body: '' });
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  // Toggle functions
  const toggle = () => setModal(!modal);

  // onChange functions
  const onInputsChange = e => setInputs({ ...inputs, [e.target.name]: e.target.value });

  // onClick functions
  const onSendConfirmationClick = _ => confirmEmail({ email: inputs.email });

  // Lifecycle hooks
  useEffect(() => {
    getReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // onSubmit function
  const onSubmit = e => {
    e.preventDefault();
    setLoading(true);
    postReview({ ...inputs, email: inputs.email?.toLowerCase(), title: `${inputs.title} • ${inputs.stars}⭐` });
    setLoading(false);
  };

  // JSX
  return (
    <section id='reviews-section' className='page p-t-xl p-b-xl'>
      <h1 className='t-align-c'>Reviews</h1>
      <h4 className='text-gradient t-align-c'>{reviews.length} Reviews</h4>
      <CustomCarousel items={reviews} fade={false} dark={true} indicators={false} />
      <hr />
      <div className='t-align-c'>
        <TextButton onClick={toggle}>Leave a Review</TextButton>
      </div>

      <Modal isOpen={modal} toggle={toggle}>
        <Form onSubmit={onSubmit}>
          <ModalHeader toggle={toggle}>Leave Your Review</ModalHeader>
          <ModalBody>
            <FormGroup>
              <CustomInput required name='title' placeholder='Name' value={inputs.title} onChange={onInputsChange} />
            </FormGroup>
            <FormGroup>
              <InputGroup>
                <CustomInput required name='email' placeholder='Email' value={inputs.email} onChange={onInputsChange} />
                <CustomButton onClick={onSendConfirmationClick} color='success'>
                  Send Code
                </CustomButton>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <CustomInput
                required
                name='code'
                placeholder='One Time Passcode'
                value={inputs.node}
                onChange={onInputsChange}
              />
              <FormText>Your One Time Passcode (OTP) can only be used once</FormText>
            </FormGroup>
            <FormGroup>
              <CustomInput
                required
                name='phone'
                type='tel'
                placeholder='Mobile'
                value={inputs.phone}
                onChange={onInputsChange}
              />
            </FormGroup>
            <FormGroup>
              <CustomInput
                required
                name='body'
                type='textarea'
                rows='3'
                placeholder='Your Review'
                value={inputs.body}
                onChange={onInputsChange}
              />
            </FormGroup>

            <h2 className='text-gradient t-align-c'>{inputs.stars} ⭐</h2>
            <FormGroup>
              <CustomInput
                name='stars'
                type='range'
                min={0}
                max={5}
                step={0.5}
                value={inputs.stars}
                onChange={onInputsChange}
                style={{ padding: '2rem' }}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <CustomButton disabled={loading ? true : false} type='submit' color='primary'>
              {loading ? <Spinner size='sm' /> : 'Submit'}
            </CustomButton>
            <CustomButton onClick={toggle}>Close</CustomButton>
          </ModalFooter>
        </Form>
      </Modal>
    </section>
  );
};

const mapStateToProps = state => ({ reviews: state.reviews });

export default connect(mapStateToProps, { confirmEmail, getReviews, postReview })(Reviews);
