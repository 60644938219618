/////////////////////////////////////////////////////////////////////////////////////TYPES
import {
  GET_ITEMS_SUCCESS,
  GET_ITEMS_FAILURE,
  POST_ITEM_SUCCESS,
  POST_ITEM_FAILURE,
  PUT_ITEM_SUCCESS,
  PUT_ITEM_FAILURE,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_FAILURE,
  RESET_BASKET_FAILURE,
  RESET_BASKET_SUCCESS,
} from './types/types';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from './alert';
//////////////////////////////////////////////////////////////////////////////////////////

// GET
export const getItemsSuccess = items => ({ type: GET_ITEMS_SUCCESS, items });
export const getItemsFailure = errorMessage => ({ type: GET_ITEMS_FAILURE, errorMessage });
export const getItems = () => async dispatch => {
  try {
    const wishlist = JSON.parse(localStorage.getItem('wishlist'));
    dispatch(getItemsSuccess(wishlist.items));
  } catch (e) {
    dispatch(setAlert(e.message, true, null, GET_ITEMS_FAILURE));
    dispatch(getItemsFailure(e.message));
  }
};

// POST
export const postItemSuccess = items => ({ type: POST_ITEM_SUCCESS, items });
export const postItemFailure = errorMessage => ({ type: POST_ITEM_FAILURE, errorMessage });
export const postItem = item => async dispatch => {
  try {
    const wishlist = JSON.parse(localStorage.getItem('wishlist'));
    const currentItem = wishlist.items.find(i => i.id === item.id);
    const updated = !!currentItem
      ? wishlist.items.map(i => {
          if (i.id === item.id) {
            return { ...i, quantity: Number(i.quantity) + 1 };
          } else return i;
        })
      : [...wishlist.items, item];

    localStorage.setItem('wishlist', JSON.stringify({ ...wishlist, items: updated }));
    dispatch(postItemSuccess(updated));
  } catch (e) {
    dispatch(setAlert(e.message, true, null, POST_ITEM_FAILURE));
    dispatch(postItemFailure(e.message));
  }
};

// PUT
export const putItemSuccess = items => ({ type: PUT_ITEM_SUCCESS, items });
export const putItemFailure = errorMessage => ({ type: PUT_ITEM_FAILURE, errorMessage });
export const putItem = item => async dispatch => {
  try {
    const wishlist = JSON.parse(localStorage.getItem('wishlist'));
    const updated = wishlist.items.map(i => {
      if (i.id === item.id) {
        return { ...i, ...item };
      } else return i;
    });
    localStorage.setItem('wishlist', JSON.stringify({ ...wishlist, items: updated }));
    dispatch(putItemSuccess(updated));
  } catch (e) {
    dispatch(setAlert(e.message, true, null, PUT_ITEM_FAILURE));
    dispatch(putItemFailure(e.message));
  }
};

// DELETE
export const deleteItemSuccess = items => ({ type: DELETE_ITEM_SUCCESS, items });
export const deleteItemFailure = errorMessage => ({ type: DELETE_ITEM_FAILURE, errorMessage });
export const deleteItem = id => async dispatch => {
  try {
    const wishlist = JSON.parse(localStorage.getItem('wishlist'));
    const filtered = wishlist.items.filter(i => i.id !== id);
    localStorage.setItem('wishlist', JSON.stringify({ ...wishlist, items: filtered }));
    dispatch(deleteItemSuccess(filtered));
  } catch (e) {
    dispatch(setAlert(e.message, true, null, DELETE_ITEM_FAILURE));
    dispatch(deleteItemFailure(e.message));
  }
};

// RESET
export const resetWishlistSuccess = () => ({ type: RESET_BASKET_SUCCESS });
export const resetWishlistFailure = errorMessage => ({ type: RESET_BASKET_FAILURE, errorMessage });
export const resetWishlist = () => async dispatch => {
  try {
    localStorage.setItem('wishlist', JSON.stringify({ items: [], total: 0, duration: 0 }));
    dispatch(resetWishlistSuccess());
  } catch (e) {
    dispatch(setAlert(e.message, true, null, RESET_BASKET_FAILURE));
    dispatch(resetWishlistFailure(e.message));
  }
};
