/////////////////////////////////////////////////////////////////////////////////////TYPES
import {
  PUT_SETTINGS_FAILURE,
  PUT_SETTINGS_SUCCESS,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILURE,
} from '../actions/types/types';
////////////////////////////////////////////////////////////////////SETTINGS DEFAULT STATE
const bookingsDefaultState = {
  workingHours: {
    Mon: { start: 32400000, end: 61200000 },
    Tue: { start: 32400000, end: 61200000 },
    Wed: { start: 32400000, end: 61200000 },
    Thu: { start: 32400000, end: 61200000 },
    Fri: { start: 32400000, end: 61200000 },
    Sat: { start: 32400000, end: 61200000 },
    Sun: { start: 32400000, end: 61200000 },
  },
  interval: 900000,
  earliestBooking: 0,
  roundBooking: 'up',
  type: 'global',
};
//////////////////////////////////////////////////////////////////////////SETTINGS REDUCER
const bookings = (state = bookingsDefaultState, action) => {
  switch (action.type) {
    // GET request
    case GET_SETTINGS_SUCCESS:
      return typeof action.settings === 'object' && !Array.isArray(action.settings)
        ? { ...action.settings }
        : { state };
    case GET_SETTINGS_FAILURE:
      return { ...state };

    // PUT request
    case PUT_SETTINGS_SUCCESS:
      return { ...state, ...action.settings };
    case PUT_SETTINGS_FAILURE:
      return { ...state };

    default:
      return state;
  }
};

export default bookings;
