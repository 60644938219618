///////////////////////////////////////////////////////////////////////////////////MODULES
import { api } from '../utils/httpRequest';
import errorParser from '@ibiliaze/http-error-parser';
/////////////////////////////////////////////////////////////////////////////////////TYPES
import {
  GET_BOOKINGS_SUCCESS,
  GET_BOOKINGS_FAILURE,
  POST_BOOKING_SUCCESS,
  POST_BOOKING_FAILURE,
  PUT_BOOKING_SUCCESS,
  PUT_BOOKING_FAILURE,
  DELETE_BOOKING_SUCCESS,
  DELETE_BOOKING_FAILURE,
  DELETE_BOOKINGS_SUCCESS,
  DELETE_BOOKINGS_FAILURE,
} from './types/types';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from './alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from '../utils/resolveEnv';
import { sendMessage } from '../utils/socket';
//////////////////////////////////////////////////////////////////////////////////////////

// GET /api/booking
export const getBookingsSuccess = bookings => ({ type: GET_BOOKINGS_SUCCESS, bookings });
export const getBookingsFailure = errorMessage => ({ type: GET_BOOKINGS_FAILURE, errorMessage });
export const getBookings = endpoint => async dispatch => {
  try {
    const res = await api.get(!endpoint ? `${resolveHost()}/api/booking` : `${resolveHost()}/api/booking${endpoint}`);

    dispatch(setAlert(res.data.message, false, res.status, GET_BOOKINGS_SUCCESS));
    dispatch(getBookingsSuccess(res.data));
  } catch (e) {
    // dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, GET_BOOKINGS_FAILURE));
    dispatch(getBookingsFailure(e.message));
  }
};

// POST /api/booking
export const postBookingSuccess = booking => ({ type: POST_BOOKING_SUCCESS, booking });
export const postBookingFailure = errorMessage => ({ type: POST_BOOKING_FAILURE, errorMessage });
export const postBooking = data => async dispatch => {
  try {
    dispatch(setAlert('Saving the booking...', false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/booking`, data);

    dispatch(setAlert(res.data.message, false, res.status, POST_BOOKING_SUCCESS));
    dispatch(postBookingSuccess(res.data.booking));
    sendMessage({ action: 'postBooking', booking: res.data.booking });
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, POST_BOOKING_FAILURE));
    dispatch(postBookingFailure(e.message));
  }
};

// POST /api/booking/admin
export const postBookingAdmin = data => async dispatch => {
  try {
    dispatch(setAlert('Saving the booking...', false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/booking/admin`, data);

    dispatch(setAlert(res.data.message, false, res.status, POST_BOOKING_SUCCESS));
    dispatch(postBookingSuccess(res.data.booking));
    sendMessage({ action: 'postBooking', booking: res.data.booking });
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, POST_BOOKING_FAILURE));
    dispatch(postBookingFailure(e.message));
  }
};

// PUT /api/booking
export const putBookingSuccess = booking => ({ type: PUT_BOOKING_SUCCESS, booking });
export const putBookingFailure = errorMessage => ({ type: PUT_BOOKING_FAILURE, errorMessage });
export const putBooking = data => async dispatch => {
  try {
    dispatch(setAlert('Updating the booking...', false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/booking/${data.bookingId}`, data);

    dispatch(setAlert(res.data.message, false, res.status, PUT_BOOKING_SUCCESS));
    dispatch(putBookingSuccess(res.data.booking));
    sendMessage({ action: 'putBooking', booking: res.data.booking });
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, PUT_BOOKING_FAILURE));
    dispatch(putBookingFailure(e.message));
  }
};

// DELETE /api/booking
export const deleteBookingSuccess = booking => ({ type: DELETE_BOOKING_SUCCESS, booking });
export const deleteBookingFailure = errorMessage => ({ type: DELETE_BOOKING_FAILURE, errorMessage });
export const deleteBooking = bookingId => async dispatch => {
  try {
    dispatch(setAlert('Deleting the booking...', false, null, null, true));
    const res = await api.delete(`${resolveHost()}/api/booking/${bookingId}`);

    dispatch(setAlert(res.data.message, false, res.status, DELETE_BOOKING_SUCCESS));
    dispatch(deleteBookingSuccess(res.data.booking));
    sendMessage({ action: 'deleteBooking', booking: res.data.booking });
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, DELETE_BOOKING_FAILURE));
    dispatch(deleteBookingFailure(e.message));
  }
};

// DELETE /api/bookings
export const deleteBookingsSuccess = () => ({ type: DELETE_BOOKINGS_SUCCESS });
export const deleteBookingsFailure = errorMessage => ({ type: DELETE_BOOKINGS_FAILURE, errorMessage });
export const deleteBookings = endpoint => async dispatch => {
  try {
    dispatch(setAlert('Deleting the bookings...', false, null, null, true));
    const res = await api.delete(
      !endpoint ? `${resolveHost()}/api/bookings` : `${resolveHost()}/api/bookings${endpoint}`
    );

    dispatch(setAlert(res.data.message, false, res.status, DELETE_BOOKINGS_SUCCESS));
    dispatch(deleteBookingsSuccess());
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, DELETE_BOOKINGS_FAILURE));
    dispatch(deleteBookingsFailure(e.message));
  }
};
