///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  CustomInput,
  CustomButton,
  InputGroup,
  Spinner,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  FormGroup,
  Label,
  ButtonGroup,
} from '@ibiliaze/reactstrap';
import errorParser from '@ibiliaze/http-error-parser';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from '../../actions/alert';
import { deleteBookings } from '../../actions/bookings';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { api } from '../../utils/httpRequest';
import { resolveHost } from '../../utils/resolveEnv';
//////////////////////////////////////////////////////////////////////////////////////////

const ManagementHeader = ({ onSearchClickHandler, getBookings, setAlert, deleteBookings, firstDay }) => {
  // State
  const [inputs, setInputs] = useState({ filter: 'All', bookingId: '', removeStart: '', removeEnd: '' });
  const [loading, setLoading] = useState(false);

  // onChange functions
  const onInputsChange = e => setInputs({ ...inputs, [e.target.name]: e.target.value });

  // onClick functions
  const onSearchClick = _ => {
    setLoading(true);
    const req = async () => {
      try {
        const res = await api.get(`${resolveHost()}/api/booking/${inputs.bookingId}`);
        if (typeof res.data === 'object' && !Array.isArray(res.data)) {
          onSearchClickHandler(res.data);
        } else {
          throw new Error('Booking not found');
        }
      } catch (e) {
        setAlert(errorParser(e), true, e.response ? e.response.status : 500, null);
      }
    };
    req();
    setLoading(false);
  };

  const onRemoveClick = _ => {
    setLoading(true);
    deleteBookings(
      `?after=${new Date(inputs.removeEnd).getTime() || 0}&before=${
        new Date(inputs.removeStart).getTime() || 99999999999999999
      }`
    );
    setLoading(false);
  };

  const onRemoveAllClick = _ => {
    setLoading(true);
    deleteBookings();
    setLoading(false);
  };

  const onRefreshClick = _ => {
    setLoading(true);
    const endpoint = inputs.filter === 'Paid' ? '&paid=true' : inputs.filter === 'Unpaid' ? '&paid=false' : '';
    getBookings(`?after=${firstDay}&before=${firstDay + 604800000}` + endpoint);
    setLoading(false);
  };

  // Lifecycle hooks
  useEffect(() => {
    const endpoint = inputs.filter === 'Paid' ? '&paid=true' : inputs.filter === 'Unpaid' ? '&paid=false' : '';
    getBookings(`?after=${firstDay}&before=${firstDay + 604800000}` + endpoint);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs.filter]);

  useEffect(() => {
    setInputs(c => ({ ...c, filter: 'All' }));
  }, [firstDay]);

  // JSX
  return (
    <>
      <InputGroup>
        <CustomInput type='select' name='filter' value={inputs.filter} onChange={onInputsChange}>
          <option>All</option>
          <option>Paid</option>
          <option>Unpaid</option>
        </CustomInput>
        <CustomInput
          placeholder='635ac304323fffccf7e66b66'
          name='bookingId'
          value={inputs.bookingId}
          onChange={onInputsChange}
        />
        <CustomButton disabled={loading ? true : false} color='primary' onClick={onSearchClick}>
          {loading ? <Spinner size='sm' /> : 'Search'}
        </CustomButton>
      </InputGroup>
      <br />
      <div style={{ float: 'right' }}>
        <CustomButton color='danger' id='remove-bookings'>
          Remove Bookings
        </CustomButton>{' '}
        <CustomButton disabled={loading ? true : false} onClick={onRefreshClick} color='primary'>
          {loading ? <Spinner size='sm' /> : 'Refresh'}
        </CustomButton>
      </div>
      <UncontrolledPopover placement='left' target='remove-bookings' trigger='legacy'>
        <PopoverHeader>Select Dates</PopoverHeader>
        <PopoverBody>
          <FormGroup>
            <Label>Remove all before:</Label>
            <CustomInput
              name='removeStart'
              value={inputs.removeStart}
              onChange={onInputsChange}
              type='datetime-local'
            ></CustomInput>
          </FormGroup>
          <FormGroup>
            <Label>Remove all after:</Label>
            <CustomInput
              name='removeEnd'
              value={inputs.removeEnd}
              onChange={onInputsChange}
              type='datetime-local'
            ></CustomInput>
          </FormGroup>
          <ButtonGroup>
            <CustomButton id='remove-selected-bookings' color='danger'>
              Remove Bookings
            </CustomButton>
            <CustomButton id='remove-all-bookings' outline color='danger'>
              Remove All Bookings
            </CustomButton>

            <UncontrolledPopover placement='bottom' target='remove-selected-bookings' trigger='legacy'>
              <PopoverBody>
                <h6>This will delete all the selected bookings. Are you sure you want to continue?</h6>
                <ButtonGroup>
                  <CustomButton color='danger' onClick={onRemoveClick}>
                    Continue
                  </CustomButton>
                  <CustomButton>Cancel</CustomButton>
                </ButtonGroup>
              </PopoverBody>
            </UncontrolledPopover>

            <UncontrolledPopover placement='bottom' target='remove-all-bookings' trigger='legacy'>
              <PopoverBody>
                <h6>This will delete all bookings. Are you sure you want to continue?</h6>
                <ButtonGroup>
                  <CustomButton color='danger' onClick={onRemoveAllClick}>
                    Continue
                  </CustomButton>
                  <CustomButton>Cancel</CustomButton>
                </ButtonGroup>
              </PopoverBody>
            </UncontrolledPopover>
          </ButtonGroup>
        </PopoverBody>
      </UncontrolledPopover>
      <br />
      <br />
    </>
  );
};

export default connect(null, { setAlert, deleteBookings })(ManagementHeader);
