/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from '../actions/types/types';
////////////////////////////////////////////////////////////////////BOOKINGS DEFAULT STATE
const bookingsDefaultState = [];
//////////////////////////////////////////////////////////////////////////BOOKINGS REDUCER
const bookings = (state = bookingsDefaultState, action) => {
  switch (action.type) {
    // GET request
    case types.GET_BOOKINGS_SUCCESS:
      return Array.isArray(action.bookings) ? [...action.bookings] : [action.bookings];
    case types.GET_BOOKINGS_FAILURE:
      return [...state];

    // POST request
    case types.POST_BOOKING_SUCCESS:
      return [...state, action.booking];
    case types.POST_BOOKING_FAILURE:
      return [...state];

    // PUT request
    case types.PUT_BOOKING_SUCCESS:
      return state.map(booking => {
        if (booking._id === action.booking._id) {
          return { ...booking, ...action.booking };
        } else {
          return booking;
        }
      });
    case types.PUT_BOOKING_FAILURE:
      return [...state];

    // DELETE request
    case types.DELETE_BOOKING_SUCCESS:
      return state.filter(booking => booking._id !== action.booking._id);
    case types.DELETE_BOOKING_FAILURE:
    case types.DELETE_BOOKINGS_FAILURE:
      return [...state];

    default:
      return state;
  }
};

export default bookings;
