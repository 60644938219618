export const env = process.env;

const constants = {
  cust: 'Kochal Barber',
  custSm: 'kochal barber',
  custJs: 'kochalbarber',
  slogan: 'Experience The Experience',
  phone1: '02083604688',
  // email1: 'fahaaeshtetics@gmail.com',
  hqAddr: '8 Avenue parade, N21 2AX, London',
  chatPerson: 'Timz',
  chatMessage: `Hello there! \n \nHow can we help?`,
  chatStatus: `Typically replies within a few minutes`,
  mainPic: '/img/main.gif',
  mainPicLogo: '/img/logo.png',
  mainPicButton: 'Book an Appointment',
  instagram: 'kochalbarber',
  // facebook: 'fahaaeshtetics',
  // twitter: 'fahaaeshtetics',
  // banner: {
  //   title: 'Warning',
  //   text: 'Warning text',
  //   bottomText: 'More warning',
  // },
  secondaryPic: '/img/bikini.jpg',
  secondaryPicTitle: 'BEAUTY',
  secondaryPicText:
    "Aesthetics and beauty play a crucial role in enhancing one's confidence and self-image. At our beauty clinic, we believe that everyone deserves to feel their best, and that's why we strive to provide the highest quality aesthetic treatments.",
  secondaryPicButton: 'Learn More',
  aboutUs: [
    {
      header: 'Master Barber',
      body: 'Our skilled master barbers bring precision and style to every haircut, ensuring you leave looking and feeling your best.',
    },
    {
      header: 'Scalp Micro Pigmintation',
      body: 'Experience the transformative art of scalp micro pigmentation, a non-invasive solution for a fuller, natural-looking hairline.',
    },
    {
      header: 'Out of Work Hours',
      body: 'We understand your busy schedule, which is why we offer convenient out-of-work hours, so you can get that fresh haircut anytime it suits you.',
    },
  ],
  services: {
    currency: '£',
    serviceGroups: [
      {
        serviceGroupName: 'Popular Services',
        serviceItems: [
          {
            serviceName: 'Haircut',
            id: 'Haircut',
            imageSrc: '/img/Haircut.jpg',
            description: 'Complimentary threading 🧵 with every haircut',
            price: 22,
            duration: '00:30',
            courses: 1,
          },
          {
            serviceName: 'Haircut & Beard Trim With Razor Sculpting',
            id: 'Haircut & Beard Trim With Razor Sculpting',
            imageSrc: '/img/Haircut&BeardTrimWithRazorSculpting.jpg',
            description: '🧵 ',
            price: 35,
            duration: '00:45',
            courses: 1,
          },
          {
            serviceName: 'Haircut & Beard Trim Without Razor',
            id: 'Haircut & Beard Trim Without Razor',
            imageSrc: '/img/Haircut&BeardTrimWithoutRazor.jpg',
            description: '🧵 ',
            price: 30,
            duration: '00:45',
            courses: 1,
          },
        ],
      },
      {
        serviceGroupName: 'Other Services',
        serviceItems: [
          {
            serviceName: 'Scalp micro pigmentation',
            id: 'Scalp micro pigmentation',
            imageSrc: '/img/ScalpMicroPigmentation.jpg',
            description:
              "Scalp Micropigmentation (also known as SMP) is more than just a hair tattoo. It's a popular non-surgical hair loss treatment during which natural pigments are applied within the dermal layer of the scalp, replicating a natural shaved head look, hence making it a great solution to hair . (Call to book)",
            price: 1200,
            duration: '12:00',
            courses: 1,
          },
          {
            serviceName: 'Groom',
            id: 'Groom',
            imageSrc: '/img/Groom.jpg',
            description:
              'Bringing out your best with : HD enhancements including beard tint , hairline tint , haircut, hot towel scented shave, glowing face mask , wash , wax 🧵 🔥',
            price: 65,
            duration: '01:15',
            courses: 1,
          },
          {
            serviceName: 'Haircut - Shave - Beard Tint',
            id: 'Haircut - Shave - Beard Tint',
            imageSrc: '/img/Haircut-Shave-BeardTint.jpg',
            description: 'Wax 🧵 🔥',
            price: 50,
            duration: '00:45',
            courses: 1,
          },
          {
            serviceName: 'Haircut & Hot towel shave',
            id: 'Haircut & Hot towel shave',
            imageSrc: '/img/Haircut&hottowelshave.jpg',
            description: 'Complimentary 🧵 🔥 wax',
            price: 40,
            duration: '00:45',
            courses: 1,
          },
          {
            serviceName: 'Wash & Haircut',
            id: 'Sit back and get a massage while getting your hair washed',
            imageSrc: '/img/placeholder.png',
            description: '🧵 ',
            price: 27,
            duration: '00:30',
            courses: 1,
          },
          {
            serviceName: 'Children Under 13',
            id: 'Children Under 13',
            imageSrc: '/img/ChildrenUnder13.jpg',
            description: 'Except Friday & Saturday',
            price: 19,
            duration: '00:30',
            courses: 1,
          },
          {
            serviceName: 'Senior Citizen',
            id: 'Senior Citizen',
            imageSrc: '/img/placeholder.png',
            description: '',
            price: 18,
            duration: '00:15',
            courses: 1,
          },
          {
            serviceName: 'Hot Towel Shave',
            id: 'Hot Towel Shave',
            imageSrc: '/img/placeholder.png',
            description: 'Complimentary threading,wax & 🔥',
            price: 20,
            duration: '00:15',
            courses: 1,
          },
          {
            serviceName: 'Hair Tint',
            id: 'Hair Tint',
            imageSrc: '/img/HairTint.jpeg',
            description: '',
            price: 17,
            duration: '00:30',
            courses: 1,
          },
          {
            serviceName: 'Beard trim with razor sculpting',
            id: 'Beard trim with razor sculpting',
            imageSrc: '/img/Beardtrimwithrazorsculpting.jpg',
            description: '',
            price: 15,
            duration: '00:15',
            courses: 1,
          },
          {
            serviceName: 'Beard Tint',
            id: 'Beard Tint',
            imageSrc: '/img/BeardTint.jpg',
            description: '',
            price: 15,
            duration: '00:15',
            courses: 1,
          },
          {
            serviceName: 'Face Mask',
            id: 'Face Mask',
            imageSrc: '/img/FaceMask.jpg',
            description: '',
            price: 8,
            duration: '00:15',
            courses: 1,
          },
          {
            serviceName: 'Beard Trim',
            id: 'Beard Trim',
            imageSrc: '/img/BeardTrim.jpg',
            description: '',
            price: 10,
            duration: '00:15',
            courses: 1,
          },
          {
            serviceName: 'Shape Up',
            id: 'Shape Up',
            imageSrc: '/img/ShapeUp.jpg',
            description: '',
            price: 10,
            duration: '00:15',
            courses: 1,
          },
          {
            serviceName: 'Threading (Complimentary with every hair cut)',
            id: 'Threading (Complimentary with every hair cut)',
            imageSrc: '/img/placeholder.png',
            description: '',
            price: 8,
            duration: '00:15',
            courses: 1,
          },
          {
            serviceName: 'Waxing (Complimentary with Hot Towel Shave)',
            id: 'Waxing (Complimentary with Hot Towel Shave)',
            imageSrc: '/img/Waxing(ComplimentarywithHotTowelShave).jpg',
            description: '',
            price: 8,
            duration: '00:15',
            courses: 1,
          },
          {
            serviceName: 'Beard Relaxing',
            id: 'Beard Relaxing',
            imageSrc: '/img/placeholder.png',
            description: '',
            price: 40,
            duration: '01:00',
            courses: 1,
          },
          {
            serviceName: 'Hair Relaxing',
            id: 'Hair Relaxing',
            imageSrc: '/img/placeholder.png',
            description: '',
            price: 60,
            duration: '01:30',
            courses: 1,
          },
        ],
      },
      {
        serviceGroupName: 'Before and After Hours',
        serviceItems: [
          {
            serviceName: 'Haircut & hot towel shave',
            id: 'Haircut & hot towel shave',
            imageSrc: '/img/Haircut&hottowelshave.jpg',
            description: 'Call to book',
            price: 60,
            duration: '00:45',
            courses: 1,
          },
          {
            serviceName: 'Haircut & Beard Trim With Razor Sculpting',
            id: 'Haircut & Beard Trim With Razor Sculpting',
            imageSrc: '/img/Haircut&BeardTrimWithRazorSculpting.jpg',
            description: 'Call to book',
            price: 50,
            duration: '00:45',
            courses: 1,
          },
          {
            serviceName: 'Haircut & beard trim',
            id: 'Haircut & beard trim',
            imageSrc: '/img/Beardtrimwithrazorsculpting.jpg',
            description: 'Call to book',
            price: 45,
            duration: '00:45',
            courses: 1,
          },
          {
            serviceName: 'Haircut',
            id: 'Haircut',
            imageSrc: '/img/Haircut.jpg',
            description: 'Call to book',
            price: 40,
            duration: '00:30',
            courses: 1,
          },
        ],
      },
    ],
  },
  reviews: [
    {
      title: 'Milly',
      body: 'Faha is extremely friendly, her energy is just amazing. I have had 6 full body sessions so far and results are amazing',
    },
    {
      title: 'Farida',
      body: 'Best full body laser treatment in years. The results are very apparent. Faha is very honest and very reliable. Quality conversations are everything!',
    },
  ],
};

export default constants;
