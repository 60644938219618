/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from '../actions/types/types';
/////////////////////////////////////////////////////////////////////REVIEWS DEFAULT STATE
const reviewsDefaultState = [];
///////////////////////////////////////////////////////////////////////////REVIEWS REDUCER
const reviews = (state = reviewsDefaultState, action) => {
  switch (action.type) {
    // GET request
    case types.GET_REVIEWS_SUCCESS:
      return Array.isArray(action.reviews) ? [...action.reviews] : [action.reviews];
    case types.GET_REVIEWS_FAILURE:
      return [...state];

    // POST request
    case types.POST_REVIEW_SUCCESS:
      return [...state, action.review];
    case types.POST_REVIEW_FAILURE:
      return [...state];

    // DELETE request
    case types.DELETE_REVIEW_SUCCESS:
      return state.filter(review => review._id !== action.review._id);
    case types.DELETE_REVIEW_FAILURE:
      return [...state];

    default:
      return state;
  }
};

export default reviews;
