///////////////////////////////////////////////////////////////////////////////////MODULES
import io from 'socket.io-client';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from './resolveEnv';
import constants from '../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const socket = io(resolveHost());
socket.emit('connected', localStorage.getItem(`${constants.custJs}_clientId`));

export const sendMessage = payload => socket.emit('sendEvent', payload);
export default socket;
