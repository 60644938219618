///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { getBookingsSuccess, postBookingSuccess, putBookingSuccess, deleteBookingSuccess } from './bookings';
//////////////////////////////////////////////////////////////////////////////////////////

// Handle task response (socket.io)
export const handleResponse = data => async dispatch => {
  try {
    if (!data) throw new Error('Socket error');

    switch (data.action) {
      case 'getBookings':
        return dispatch(getBookingsSuccess(data?.bookings)); // not used by: getBookings() action in this case
      case 'postBooking':
        return dispatch(postBookingSuccess(data?.booking));
      case 'putBooking':
        return dispatch(putBookingSuccess(data?.booking));
      case 'deleteBooking':
        return dispatch(deleteBookingSuccess(data?.booking));
      default:
        return;
    }
  } catch (e) {}
};
