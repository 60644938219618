/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from '../actions/types/types';
////////////////////////////////////////////////////////////////////////AUTH DEFAULT STATE
const userDefaultState = {
  isAuthenticated: false,
  user: null,
};
//////////////////////////////////////////////////////////////////////////////AUTH REDUCER
const user = (state = userDefaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    // Register
    case types.REGISTER_SUCCESS:
      return { ...state, user: null, isAuthenticated: false };

    // Login
    case types.LOGIN_SUCCESS:
      return { ...state, ...payload, isAuthenticated: true };

    // GET request
    case types.GET_USER_SUCCESS:
      return { ...state, ...payload, isAuthenticated: true };

    // Auth failure & logout
    case types.LOGIN_FAILURE:
    case types.REGISTER_FAILURE:
    case types.GET_USER_FAILURE:
    case types.LOGOUT_SUCCESS:
      return { ...state, user: null, isAuthenticated: false };
    case types.LOGOUT_FAILURE:
      return state;

    // PUT request
    case types.PUT_USER_SUCCESS:
      return { ...state, user: payload.user };
    case types.PUT_USER_FAILURE:
      return { ...state };

    // DELETE request
    case types.DELETE_USER_SUCCESS:
      return { isAuthenticated: false, user: null };
    case types.DELETE_USER_FAILURE:
      return { ...state };

    default:
      return state;
  }
};

export default user;
