///////////////////////////////////////////////////////////////////////////////////MODULES
import { BrowserRouter, Route, Routes } from 'react-router-dom';
/////////////////////////////////////////////////////////////////////////////////////PAGES
import HomePage from '../components/Pages/HomePage';
import BookingsPage from '../components/Pages/BookingsPage';
import PrivacyPolicyPage from '../components/Pages/legal/PrivacyPolicyPage';
import CookiesPolicyPage from '../components/Pages/legal/CookiesPolicyPage';
import TermsConditions from '../components/Pages/legal/TermsConditions';
import DisclaimerPage from '../components/Pages/legal/DisclaimerPage';
import ReturnPolicyPage from '../components/Pages/legal/ReturnPolicyPage';
import NotFoundPage from '../components/Pages/NotFoundPage';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Header from '../components/Layout/Header';
import Alert from '../components/Layout/Alert';
// import ChatWidget from '../components/Layout/ChatWidget';
import Footer from '../components/Layout/Footer';
import ScrollUp from '../components/Layout/ScrollUp';
//////////////////////////////////////////////////////////////////////////////////////////

const AppRouter = () => (
  <BrowserRouter>
    <>
      <ScrollUp />
      <Header />
      <Alert />
      <Routes>
        <Route exact path='/' element={<HomePage />} />
        <Route exact path='/bookings' element={<BookingsPage />} />
        {/* Legal Pages */}
        <Route exact path='/privacy-policy' element={<PrivacyPolicyPage />} />
        <Route exact path='/cookie-policy' element={<CookiesPolicyPage />} />
        <Route exact path='/terms-conditions' element={<TermsConditions />} />
        <Route exact path='/disclaimer' element={<DisclaimerPage />} />
        <Route exact path='/return-policy' element={<ReturnPolicyPage />} />
        {/* 404 */}
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
      <Footer />
      {/* <ChatWidget /> */}
    </>
  </BrowserRouter>
);

export default AppRouter;
